const postMarkup = data => {
  const {
    name,
    permalink,
    image_url,
    image_alt
  } = data;
  let markup = `
          <div class="filtered-team__item">
            <div class="qodef-e-inner">
              <div class="qodef-e-image">
                <div class="qodef-e-media-image">
                  <img src="${image_url}" alt="${image_alt}" />
                </div>
              </div>
              <div class="qodef-e-content">
                <p itemprop="name" class="qodef-e-title entry-title">${name}</p>
              </div>
            </div>
          </div>
          `;
  return markup;
};
const team_container = document.getElementById("team-container");
function loadTeam(cat_name) {
  var data_cat_name = cat_name != null ? cat_name : null;
  jQuery.ajax({
    url: sandra_vars.ajaxurl,
    type: "post",
    data: {
      action: "sandra_ajax_getTeam",
      cat_name: data_cat_name
    },
    dataType: "json",
    beforeSend: function () {
      jQuery("#posts-container").empty();
      jQuery(".posts__loading").show();
      team_container.innerHTML = "";
      document.querySelector(".posts__loading").style.display = "block";
    },
    success: function (data) {
      console.log("data:", data);
      if (data && data.length > 0) {
        data.forEach(key => {
          team_container.innerHTML += postMarkup(key);
        });
      } else {
        team_container.innerHTML = `<p class="text-center">No hay posts para mostrar</p>`;
      }
      document.querySelector(".posts__loading").style.display = "none";
    },
    error: function () {
      document.querySelector(".posts__loading p").innerHTML = "Hubo un error cargando los posts";
    }
  });
}
if (team_container) {
  loadTeam();
}
const post_filters = document.querySelectorAll(".filtered-team__filter a");
if (post_filters) {
  for (let l = 0; l < post_filters.length; l++) {
    post_filters[l].addEventListener("click", () => {
      for (let lt = 0; lt < post_filters.length; lt++) {
        post_filters[lt].classList.remove("active");
      }
      post_filters[l].classList.add("active");
      const dataFilter = post_filters[l].getAttribute("data-filter");
      if (dataFilter === "all") {
        loadTeam();
      } else {
        loadTeam(dataFilter);
      }
    });
  }
}
const variable_items = document.querySelectorAll(".variable-items-wrapper li");
if (variable_items) {
  for (let w = 0; w < variable_items.length; w++) {
    const item_title = variable_items[w].dataset.title;
    const item_span = document.createElement("span");
    item_span.classList.add("variable-item-text");
    item_span.innerHTML = item_title;
    variable_items[w].appendChild(item_span);
  }
}
(function ($) {
  $(".product-gallery").slick({
    mobileFirst: true,
    arrows: false,
    dots: true,
    responsive: [{
      breakpoint: 640,
      settings: "unslick"
    }]
  });
})(jQuery);